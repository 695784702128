@font-face {
    font-family: "huynen";
    src: url("../fonts/Karinhuynen-Regular.otf");
}
@font-face {
    font-family: "sketchy";
    src: url("../fonts/FrederickatheGreat-Regular.ttf");
}

$mainFont: "huynen";
$secondaryFont: "sketchy";

$dark: rgb(20, 25, 29);
$greyish: #3f4952;
$blue: #45818E;
$lightBlue: #adbac2;


html{
    font-size: 100%;
    font-family: $mainFont;
    -webkit-box-sizing: padding-box;
    -moz-box-sizing: padding-box;
    box-sizing: border-box;
}
*, *::before, *::after{
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}
input {
    filter: none;
}


body{
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background-color: $dark;
    color: #fff;
}

#front_page{
    min-height: 100vh;
    height: 100%;
    display: grid;
    grid-template-columns: 65% 35%;
    background-color: $dark;
    color: #fff;
    cursor: default;
    overflow-y: auto;
}

#front_page > div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
    padding: 3rem 2rem;
}
h1#title{
    font-size: 5rem;
    text-align: center;
    position: relative;
}
#title::before{
    content: "";
    position: absolute;
    width: 2.75rem;
    height: 2.75rem;
    margin-left: -2rem;
    margin-top: -1.8rem;
    background-image: url('../images/twoLeafs.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    filter: invert(100%);
    transform: scaleX(-1);
}
#title::after{
    content: "";
    position: absolute;
    width: 1.8rem;
    height: 1.8rem;
    margin-top: 3.3rem;
    background-image: url('../images/oneLeaf.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    filter: invert(100%);
    rotate: 15deg;
}

h3#subtitle{
    color: $lightBlue;
    font-size: 1.4rem;
    font-family: $secondaryFont;
    text-align: center;
    margin-bottom: 2rem;
}
img.logo{
    max-width: 40rem;
}

.story{
    width: auto;
    max-width: 60rem;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
    color: $dark;
    background-color: #fff;
    border-radius: 3px;
    padding: 2rem;
    box-shadow: 0 10px 10px rgba(0, 10, 18, 0.8);
   
}
.story h4{
    font-size: 1.3rem;
    margin-bottom: 1rem;
    position: relative;
    font-family: $mainFont;
}
.story p{
    font-family: $secondaryFont;
    color: #000;
    font-size: 1.2rem;
}
.story hr{
    width: 50%;
    height: 1px;
    border: none;
    background-color: $dark;
    margin-left: calc((100% - 50%)/2);
    margin-bottom: 1rem;
}
p.info{
    width: auto;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

#grid{
    width: auto;
    display: grid;
    column-gap: 0.6rem;
    row-gap: 1rem;
}
.explanation{
    width: 12rem;
    height: 10rem;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px;
    border-color: rgba(255,255,255,0.7);
    border-style: solid;
    border-radius: 3px;
}
.explanation img{
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
}
.explanation p {
    font-family: $secondaryFont;
    font-size: 1.2rem;
    color: $lightBlue;
}

#btn{
    width: 14rem;
    padding: 1.6rem 1rem 2.6rem 1rem;
    text-align: center;
    border: 1px;
    border-color: rgba(255,255,255,0.7);
    border-style: solid;
    border-radius: 3px;
    cursor: pointer;
    opacity: 0;
    transition: 300ms;
}
#btn p {
    text-align: center;
    text-transform: uppercase;
    width: auto;
    height: 1px;
}
#btn p::after{
    content: "";
    position: absolute;
    width: 2rem;
    height: 2rem;
    margin-top: -0.5rem;
    margin-left: 0.3rem;
    background-image: url('../images/go_arrow.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    visibility: hidden;
    transition: 100ms;
}
#btn:hover{
    border: 2px;
    border-color: rgba(255,255,255,1);
    border-style: solid;
    color: $dark;
    box-shadow: inset 14rem 0 0 0 #e0e0e0;
}
#btn:hover p{
    width: 9.8rem;
}
#btn:hover p::after{
    visibility: visible;
    transition: 400ms;
}
#end_page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 100vh;
    background-color: $dark;
    color: #fff;
    cursor: default;
    overflow-y: auto;
    padding: 1rem 4rem;
}
#end_page #grid{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.end{
    opacity: 1 !important;
}
.items{
    width: 25rem !important;
}
.explanation span{
    font-size: 1rem;
    color: $greyish;
}
.not_sacrified{
    border: 2px solid red;
}
.sacrified{
    border: 2px solid green;
}

#loading{
    margin-top: -3rem;
}
#loading img{
    width: 20px;
    margin-right: 1rem;
    float: left;
}
#loading p{
    float: left;
}

//Blinking
@keyframes blink{
    0% {
      height: 50%;
    } 
    30% {
      height: 20%;
    } 
    70% {
      height: 50%;
    }
    100% {
      height: 0%;
    }
  }
  
  .blink{
    animation-name: blink;
    animation-duration: var(--duration);
    animation-iteration-count: 1;
    animation-direction: var(--direction);
    opacity: var(--opacity);
    height: var(--height);
    transition: 100ms;
  }
  
  #blink{
    height: 100%;
    width: 100vw;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top:0;
    display: none;
  }
  #blink div{
    height: 50%;
    background-color: $dark;
  }

//App
#sceneCanvas{
    width: 100vw;
    height: 100vh;
}

.ui{
    width: 25rem;
    height: 17.5rem;
    position: absolute;
    bottom: 60px;
    cursor: auto;
    opacity: 0;
    transition: 600ms;
    left: calc((100% - 25rem) /2)
}
#interaction{
    background-image: url("../images/itemUI.png");
    background-position: center center;
    background-size: cover;
}
#dialogue{
    background-image: url("../images/npcUI.png");
    background-position: center center;
    background-size: cover;
}

.ui h1{
    font-size: 1rem;
    color: $greyish;
    width: 8rem;
    text-align: center;
    text-transform: uppercase;
    font-family: $mainFont;
    position: relative;
    top: 1.45rem;
    left: 2.25rem;
    transform: rotate(-1deg);
}
#dialogue h1{
   transform: rotate(-3deg);
   top: 0.85rem;
   width: 8.1rem;
   left: 2.2rem;
}

.ui .ui_p{
    height: 12.8rem;
    width: 20.3rem;
    left: 2.4rem;
    position: relative;
    top: 2.25rem;
    font-family: $secondaryFont;
    font-size: 1rem;
    overflow-y: scroll;
    padding: 0.55rem 0.7rem;
    scrollbar-width: thin;
    scrollbar-color: $blue #14191d9c;
}
::-webkit-scrollbar {
    width: 8px;
    background: #14191d9c;
}
::-webkit-scrollbar-thumb {
    background: $blue;
}

.ui .ui_btn{
    width: 4.5rem;
    height: 3.5rem;
    right: 1.7rem;
    bottom: -2.8rem;
    background-image: url("../images/btn.png");
    background-position: center center;
    background-size: cover;
    position: relative;
    float: right;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui .ui_btn .p_btn{
    font-family: $mainFont;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: $greyish;
    height: 1.2rem;
}


#dn_slider{
    width: 100%;
    height: 50px;
    top: 0;
    opacity: 0;
    transition: 600ms;
    position: absolute;
    left: calc((100% - 300px) /2)
}
#dn_slider img{
    width: 300px;
    height: 20px;
    position: absolute;
    margin-top: 20px; 
}
img#slider{
    width: 300px;
}
img#fill{ 
    width: 0px; /*Change this with js*/
    height: 20px;
}
img#bar{ 
    width: 8px;
    height: 30px;
    margin-top: 14px;
    margin-left: 0px; /*Change this with js, same as fill width*/
}

#dn_slider::before{
    content: " ";
    position: absolute;
    width: 40px;
    height: 40px;
    background-image: url(../images/sun.png);
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 10px;
    margin-left: -50px;
}
#dn_slider::after{
    content: " ";
    position: absolute;
    width: 30px;
    height: 30px;
    background-image: url(../images/moon.png);
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 15px;
    margin-left: calc(300px + 7px);
}


#quest_ui{
    background: linear-gradient(90deg, rgba(20, 25, 29, 0.5) 0%, rgba(20, 25, 29, 0.5) 80%, rgba(0,0,0,0) 100%); 
    width: 250px;
    left: 0;
    min-height: 60px;
    height: auto;
    position: absolute;
    top: 20px;
    border-top: 6px solid #fff;
    border-bottom: 6px solid #fff;
    padding: 10px;
    visibility: hidden;
    transition: 400ms;
}
#quest_ui h1{
    font-size: 1rem;
    color: $lightBlue;
    text-transform: uppercase;
    font-family: $mainFont;
    margin-bottom: 5px;
}
#quest_ui h1:not(:first-of-type)::before{
    content: "";
    width: 100%;
    height: 1px;
    background-color: $lightBlue;
    display: inline-block;
    margin-bottom: 10px;
}
#quest_ui p{
    font-size: 1rem;
    color: #fff;
    font-family: $secondaryFont;
    margin-bottom: 10px;
}
#quest_ui p:last-of-type{
    margin-bottom: 0;
}

#help{
    position: absolute;
    top: 10px;
    background-color: rgba(20, 25, 29, 0.3);
    right: 50px;
    padding: 0.5rem;
    font-size: 0.7rem;
    border: 1px solid rgba(255,255,255,0.5);
    cursor: pointer;
    opacity: 0;
}
#audio{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 0.7rem;
    cursor: pointer;
    opacity: 1;
}
#audio img{
    width: 30px;
}
